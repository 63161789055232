import {toast} from 'react-toastify'
import {VAT, WEBSITE_URL} from '../config'
import {
  changeWarehouse,
  productReception,
  productToggleVisibility,
  toggleAvailableForOnlinePurchase,
  TProductTemplate,
} from '../lib/api/product-templates'
import {createLabel} from '../lib/label'
import {roundToTwo} from '../lib/utils/number'
import CategoryToggler from './CategoryToggler'
import {useWarehouse} from '../context/warehouse'
import {TWarehouse} from '../lib/api/warehouse'
import WarehouseDropdown from './WarehouseDropdown'
import Button from './Button'
import PriceChanger from './PriceChanger'
import {useWebsiteCategory, WebsiteCategories} from '../lib/api/public-categories'

type Props = {
  product: TProductTemplate
  refreshSearch: () => void
  afterAddClick: () => void
}

function AvailableOnlineToggler({product, refreshSearch}: Omit<Props, 'afterAddClick'>) {
  const onlinePurchaseCategory = useWebsiteCategory(WebsiteCategories.ForOnlinePurchase)

  const isAvailableForOnlinePurchase = product?.public_categ_ids?.some((c) => c === onlinePurchaseCategory?.id)

  const onClick = async () => {
    await toggleAvailableForOnlinePurchase(product, !isAvailableForOnlinePurchase)
    refreshSearch()
  }

  return (
    <Button onClick={onClick} variant="primary" className="mb-4">
      {isAvailableForOnlinePurchase ? 'Disponibil online' : 'Nedisponibil online'}
    </Button>
  )
}

function ProductToggler({product, refreshSearch, afterAddClick}: Props) {
  const {warehouse} = useWarehouse()
  const onAdd = async (published: boolean) => {
    try {
      const {barcode} = await productReception(product, {
        is_published: published,
        warehouse: warehouse as TWarehouse,
      })
      toast.success('Produs actualizat cu succes')
      await createLabel({product: {...product, barcode}})
      afterAddClick()
    } catch (error) {
      console.error(error)
      toast.error('Eroare la adăugarea produsului')
    }
  }

  const onPrint = () => createLabel({product})

  const toggleVisibility = () => {
    productToggleVisibility(product.id, !product.is_published)
    refreshSearch()
  }

  return (
    <div className="flex flex-col col-span-2 gap-2">
      <Button onClick={() => onAdd(true)} variant="success">
        ADD
      </Button>
      <Button onClick={() => onAdd(false)} variant="primary">
        ADD (hidden)
      </Button>
      <Button onClick={toggleVisibility} variant="primary">
        {product.is_published ? 'Hide' : 'Show'}
      </Button>
      <AvailableOnlineToggler {...{product, refreshSearch}} />
      {/* <Button onClick={onPrint}>PDF</Button> */}
    </div>
  )
}

export default function ProductDisplay({product, refreshSearch, afterAddClick}: Props) {
  return (
    <div className="border rounded mb-4 p-1 w-full">
      <div className="grid grid-cols-7 lg:grid-cols-9 gap-2">
        <img src={`data:image/jpeg;base64,${product.image_128}`} alt="Product" />

        <div className="col-span-4 lg:col-span-6">
          <a href={`${WEBSITE_URL}${product.website_url}`} target="_blank" rel="noreferrer">
            <div className="flex text-xl truncate">{product.name}</div>
          </a>
          <div className="text-sm truncate">{product.description_sale ?? product.description}</div>
          <div>
            <b>LPN: </b>
            {product.lpn}
            <br />
            <b>Barcode: </b> {product.barcode} | Palet: {product.palet} - {product.palet_no}
          </div>
          <div>
            Preț: {roundToTwo(product.list_price * VAT)}&nbsp;&nbsp;&nbsp;Canitate: {product.qty_available}
          </div>
          <div className="mb-4 w-full">
            <WarehouseDropdown
              warehouse={product.property_stock_inventory ? ({lot_stock_id: product.property_stock_inventory} as TWarehouse) : undefined}
              warehouses={useWarehouse().warehouses}
              setWarehouse={async (w) => {
                if (!w) return
                await changeWarehouse(product, w)
                refreshSearch()
              }}
            />
          </div>
        </div>
        <ProductToggler {...{product, refreshSearch, afterAddClick}} />
      </div>
      <PriceChanger {...{product, refreshSearch}} />
      <CategoryToggler products={[product]} onEnd={refreshSearch} />
    </div>
  )
}
