import {useRef, useState} from 'react'
import {searchProductTemplates, TProductTemplate} from '../lib/api/product-templates'
import {usePrintAll} from '../lib/utils/usePrintAll'
import {useEffectDebounced} from '../lib/utils/hooks'
import {useWarehouse} from '../context/warehouse'
import Button from './Button'
import ProductDisplay from './ProductDisplay'
import SearchBar from './SearchBar'
import WarehouseDropdown from './WarehouseDropdown'
import {useWebsiteCategory, WebsiteCategories} from '../lib/api/public-categories'

export default function ProductSearch() {
  const [searchValue, setSearchValue] = useState<{keyword: string}>()
  const keyword = searchValue?.keyword
  const setKeyword = (keyword) => setSearchValue({...searchValue, keyword})

  const [result, setResult] = useState<Array<TProductTemplate>>()
  const [refreshCnt, setRefresh] = useState(false)
  const refresh = () => setRefresh((s) => !s)
  const inputRef = useRef<HTMLInputElement>()

  useEffectDebounced(
    () => {
      console.log('@@@sarch', searchValue)
      searchProductTemplates(searchValue?.keyword).then(({data}) => setResult(data))
    },
    100,
    [searchValue?.keyword, refreshCnt]
  )

  const afterAddClick = () => {
    setKeyword('')
    inputRef?.current?.focus?.()
  }

  const {printAllProducts, loading, progress, target} = usePrintAll({products: result})
  useWebsiteCategory(WebsiteCategories.ForOnlinePurchase)
  return (
    <div className="flex flex-col items-center max-w-3xl w-full">
      <SearchBar onChange={setSearchValue} inputRef={inputRef} keyword={keyword} setKeyword={setKeyword} />
      <WarehouseDropdown {...useWarehouse()} autoLoadFirst />

      <div className="h-4" />
      <div className="flex flex-row gap-2 items-center mb-2">
        Results: {result?.length}
        {result?.length ? (
          <Button onClick={printAllProducts} disabled={loading}>
            Print all
          </Button>
        ) : null}
        {loading && (
          <div>
            Loading ... {progress} / {target}
          </div>
        )}
      </div>
      {result?.map?.((product) => (
        <ProductDisplay key={String(product.id)} product={product} refreshSearch={refresh} afterAddClick={afterAddClick} />
      ))}
    </div>
  )
}
