import {useAuth} from './context/auth'
import Main from './pages/Main'
import Login from './pages/Login'
import {WebsiteCategoriesProvider} from './lib/api/public-categories'

export default function App() {
  const {loggedIn, initialised} = useAuth()
  if (!initialised) return <div>Loading ...</div>
  if (!loggedIn) return <Login />

  return (
    <WebsiteCategoriesProvider>
      <Main />
    </WebsiteCategoriesProvider>
  )
}
