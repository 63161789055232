// export const API_URL = `https://${document.location.hostname.replace('dashy.', 'api.')}/`
// export const WEBSITE_URL = `https://${document.location.hostname.replace('dashy.', '')}/`

// TBB
// export const API_URL = `https://api.thebigbox.ro/`
// export const WEBSITE_URL = `https://thebigbox.ro/`

// MDS
export const API_URL = `https://api.megadiscountstore.ro/`
export const WEBSITE_URL = `https://megadiscountstore.ro/`

export const VAT = 1.19

export const QR_SHORT_URL = `${API_URL}/qr.php?c=`
export const CHEAP_PRICE_LIMIT = 50

export let PRINT_INITIAL_PRICE = true
export let PRINT_LOGO = false
export let PRINT_WIDTH = 5
export let PRINT_HEIGHT = 7
export let VARIABLE_PERCENT_2_CATEGORY = false
export let PRICING_V2 = false

const CLIENT = API_URL.includes('thebigbox') ? 'TBB' : 'MEGA'

if (CLIENT === 'MEGA') {
  PRINT_INITIAL_PRICE = false
  PRINT_LOGO = true
  PRINT_WIDTH = 5
  PRINT_HEIGHT = 10
} else {
  VARIABLE_PERCENT_2_CATEGORY = true
  PRICING_V2 = true
}
